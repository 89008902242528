import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { msalConfig } from "../authConfig";
import { Navigate } from "react-router-dom";
import { routes } from "../util/constants";

export default function Home() {
  const { instance } = useMsal();
  const handleLoginRedirect = () => {
    instance
      .loginRedirect({ scopes: [...msalConfig.auth.scopes] })
      .catch((error) => console.log(error));
  };
  const RedirectToLogin = () => <>{handleLoginRedirect()}</>;
  return (
    <>
      <UnauthenticatedTemplate>
        <RedirectToLogin />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Navigate to={routes.ADMIN_DASHBOARD} />
      </AuthenticatedTemplate>
    </>
  );
}
